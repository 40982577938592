import * as React from "react";
import Tabs from "@mui/material/Tabs";
import classes from "./customer.module.css";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Customers from "./Customers";
import Leads from "./Leads";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,

  };
}

export default function CustomerIndex() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get("state");

  const [value, setValue] = React.useState(0);

  // Update the tab based on the `state` query parameter
  useEffect(() => {
    if (state === "leads") {
      setValue(1); // Activate the Leads tab
    } else {
      setValue(0); // Default to Customers tab
    }
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Update the query parameter based on the tab selection
    if (newValue === 1) {
      navigate(`${location.pathname}?state=leads`);
    } else {
      navigate(location.pathname, { replace: true }); // Clear the query parameter
    }
  };

  return (
    <div>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.HeadTabButton}
            aria-label="basic tabs example"
          >
            <Tab
              label={"Customers"}
              {...a11yProps(0)}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              label={"Leads"}
              {...a11yProps(1)}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className={classes.TabPanel}>
          <Customers />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.TabPanel}>
          <Leads />
        </TabPanel>
      </Box>
    </div>
  );
}
