import React, { useState } from "react";
import AppLogo from "../Assets/images/HeaderLogo.png";
import smallLogo from "../Assets/images/image 2.png";
import classes from "./index.module.css";
import {
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ClickAwayListener,
  Box,
  Modal,
  Button,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  GetProfileDetails,
  ToggleSideBar,
  setHeaderName,
} from "../Redux/actions/userActions";
// import { getLanguagedataFunction } from "../utilities/language";
// import LanguageModal from "./LanguageModal";
import {
  BlackMenuBar,
  LogoutIcon,
  MyProfileIcon,
  NotificationBellIcon,
  ResetpasswordIcon,
} from "./Icons/Icons";
import { ArrowDownIcon } from "./Icons/Icons";
import Cookies from "js-cookie";
import nonotification from "../Assets/images/noNotification.png";
import { generateColor, getNotificationUnseenCount, stringAvatar } from "../Utilities/commonfunctions";
import NotificationItem from "./notificationItem";
import { GetNotificationList, handleMarkAsRead } from "../Redux/actions/notificationActions";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuopen, setMenuOpen] = useState(false);
  const rootRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const loggedUser = useSelector((state) => state.userLogin.loggedUser);
  const togglesidebar = useSelector((state) => state.userLogin.togglesidebar);
  const { notificationList } = useSelector((state) => state.notificationDate) || {};
  const headerName = useSelector((state) => state.userLogin.headerName);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const closeModal = () => {
    setLogoutModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("userInfo");
    handleClose();
    Cookies.remove("userToken")
    navigate("/login");
  };

  const handleMyProfileClick = () => {
    handleClose();
    navigate("/my-profile");
  };
  const handleResetPassword = () => {
    handleClose();
    navigate("/reset-Password");
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("invoices")) {
      dispatch(ToggleSideBar(true));
    } else {
      const Arr = path.split("/");
      const name = Arr[1].replaceAll("-", "_").toString();
      dispatch(setHeaderName(name));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!loggedUser) {
      dispatch(GetProfileDetails());
    }
  }, [loggedUser, dispatch]);

  useEffect(() => {
    dispatch(GetNotificationList(userInfo._id));
  }, [dispatch, userInfo._id])

  const handleAllMarkAsRead = () => {
    dispatch(handleMarkAsRead("all"))
    dispatch(GetNotificationList(userInfo._id))
  };

  const handleClickOpen = () => {
    setMenuOpen(true);
  };

  const handleViewAllNotification = () => {
    dispatch(setHeaderName("Notifications"));
    setMenuOpen(false);
    navigate("/notifications");
  };

  const handleSideBarToggle = () => {
    dispatch(ToggleSideBar(!togglesidebar));
  };

  // useEffect(() => {
  //   console.log("notificationList", notificationList);
  // }, [notificationList])



  const handleCloseNotification = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <div className={`${classes.header_flex} ${classes.header_maindiv}`}>
        <div className={`${classes.header_flex} ${classes.second_maindiv}`}>
          <div>
            <span className="logo-lg mb-5">
              {togglesidebar ? (
                <img src={AppLogo} alt="" className={classes.HeaderLogoImage} />
              ) : (
                <img
                  src={smallLogo}
                  alt=""
                  style={{ marginLeft: 5 }}
                />
              )}
            </span>
          </div>
          {window.location.pathname.includes("invoices") ? null : (
            <div
              className={
                !togglesidebar
                  ? `${classes.header_flex} ${classes.HeaderSmallMenu}`
                  : `${classes.header_flex} ${classes.header_MenuIcon}`
              }
            >
              <span onClick={() => handleSideBarToggle()}>
                <BlackMenuBar />
              </span>
              <Typography
                style={{ textTransform: "capitalize" }}
                className={classes.header_MenuIconMainText}
              >
                {headerName?.replaceAll("_", " ")}
              </Typography>
            </div>
          )}
        </div>
        <div
          className={`${classes.header_flex} ${classes.second_maindiv}`}
          style={{
            columnGap: "30px",
          }}
        >
          <div className={classes.notificationBar} style={{ position: "relative" }}>
            <ClickAwayListener onClickAway={handleCloseNotification}>
              <div>
                <HtmlTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  className={classes.TooltipClasses}
                  onClose={handleCloseNotification}
                  open={menuopen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <React.Fragment>
                      <div className={classes.tooltip_arrow} />
                      <div className={classes.menuItem}>
                        <Typography className={classes.TooltipHEader}>
                          Notification
                          <span className={classes.unseencount}>
                            {getNotificationUnseenCount(notificationList)}
                          </span>
                        </Typography>
                        <Button
                          variant="text"
                          className={`${classes.TooltipmarkText}`}
                          onClick={() => handleAllMarkAsRead()}
                        >
                          Mark all as read
                        </Button>
                      </div>
                      {notificationList && notificationList.length > 0 ? (
                        <div>
                          <div className={classes.notificationListContainer}>
                            {notificationList.map((item, index) => (
                              <NotificationItem item={item} key={index} />
                            ))}
                          </div>
                          <div className={classes.viewAllbtnSection}>
                            <Button
                              variant="text"
                              className={`${classes.TooltipmarkText}`}
                              onClick={() => handleViewAllNotification()}
                            >
                              View All
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.NoImaheContainer}>
                          <img src={nonotification} alt="" />
                        </div>
                      )}
                    </React.Fragment>
                  }
                >
                  <Button
                    variant="outlined"
                    onClick={handleClickOpen}
                    sx={{ Padding: 0, border: "none", minWidth: 0 }}
                    className={classes.HeaderNotificationButton}
                  >
                    <NotificationBellIcon />
                  </Button>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          </div>
          <div
            className={`${classes.header_flex} ${classes.AvatarDiv}`}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {loggedUser && loggedUser.profile_pic && loggedUser.profile_pic?.destination ? (
              <Avatar
                alt={loggedUser.name}
                src={`${process.env.REACT_APP_IMAGE_URL}/${loggedUser?.profile_pic?.destination}${loggedUser?.profile_pic?.filename}`}
              />
            ) : (
              <Avatar
                {...stringAvatar(`${loggedUser?.name}`)}
                sx={{
                  bgcolor: `${generateColor(`${loggedUser?.name}`)}`,
                }}
              />
            )}
            <div>
              {loggedUser ? (
                <div
                  className={classes.HeaderUser_Name}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  <Typography className={classes.header_MenuIconMainText}>
                    {loggedUser.name}
                  </Typography>
                  <Typography className={classes.HeaderUser_designation}>
                    admin
                  </Typography>
                </div>
              ) : null}
            </div>
            <ArrowDownIcon />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className={classes.MenuItems}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={handleMyProfileClick}
              className={classes.HeaderMenuItem}
            >
              <MyProfileIcon /> My Profile
            </MenuItem>
            <hr style={{ margin: "5px 0" }} />
            <MenuItem
              onClick={handleResetPassword}
              className={`${classes.HeaderMenuItem} ${classes.resetPAsswordMenu}`}
            >
              <ResetpasswordIcon /> Reset Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLogoutModal(true);
                handleClose();
              }}
              className={classes.HeaderMenuItem}
            >
              <LogoutIcon /> Logout
            </MenuItem>
          </Menu>
        </div>
        {/* {moddleToggle ? <LanguageModal modalClose={closeModal} /> : null} */}
      </div>
      {logoutModal ? (
        <Modal
          open={logoutModal}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            height: 480,
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{
              width: "460px",
              height: "max-content",
              top: "20%",
            }}
            className={classes.loginModalWidth}
          >
            <div className={classes.deleteConformationFirstDiv}>
              <Typography className={`mb-2 ${classes.successPageMainText}`}>Logout</Typography>
              <Typography className={classes.successPageSubText}>
                Are you sure you want to log out?
              </Typography>
              <div className={classes.addMemberModalbtnSection}>
                <Button variant="outlined" onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={() => handleLogout()}>
                  Logout
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
};

export default Header;
