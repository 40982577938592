import React, { useEffect, useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import classes from "./index.module.css";
import { Link, useNavigate } from "react-router-dom";
// import "../assets/css/index.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName } from "../Redux/actions/userActions";
// import { getLanguagedataFunction } from "../utilities/language";
import {
  AuditTrialIcon,
  BlogIcon,
  CallIcon,
  CommunicationIcon,
  CustomerIcon,
  DashboardIcon,
  FeedbackIcon,
  InvoicingIcon,
  ReferralsIcon,
  ReportsIcon,
  SubsManagementIcon,
  TicketsIcon,
  UserManagementIcons,
} from "./Icons/Icons";

const SideNavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const togglesidebar = useSelector((state) => state.userLogin.togglesidebar);
  const [currentRoute, setCurrentRoute] = useState(
    window.location.pathname.toLowerCase()
  );

  const handleNavigate = (name, route) => {
    dispatch(setHeaderName(name));
    setCurrentRoute(route);
  };

  useEffect(() => {
    console.log(currentRoute);
  }, [currentRoute]);

  useEffect(() => {
    // const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!userInfo || !userInfo.token) {
      return navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (window.location.pathname.includes("my-profile")) {
      setCurrentRoute("dashboard");
      return;
    }
    const path = window.location.pathname;
    const Arr = path.split("/");
    const name = Arr[Arr.length - 1].toString();
    setCurrentRoute(name);
  }, [window.location.pathname]);

  return (
    <div id="scrollbar">
      <div>
        {userInfo && userInfo.user_type === "admin" && (
          <div
            className={classes.nabBarContainer}
            style={{ height: window.outerHeight, minHeight: "750px" }}
          >
            {userInfo.permissions.includes("dashboard") ? (
              <Link
                className={
                  currentRoute.includes("dashboard") ? `${classes.activebtn}` : ``
                }
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button
                    variant="text"
                    onClick={() => handleNavigate("dashboard", "/dashboard")}
                    className={classes.linkburron}
                  >
                    <span className={classes.activeIcon1}>
                      <DashboardIcon />
                    </span>
                    {togglesidebar ? "Dashboard" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("customers") ? (
              <Link
                to="/customers"
                className={
                  currentRoute.includes("customers") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("customers", "/customers")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button variant="text" className={classes.linkburron}>
                    <span className={classes.activeIcon1}>
                      <CustomerIcon />
                    </span>{" "}
                    {togglesidebar ? "Customers" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("subscriptionManagement") ? (
              <Link
                to="/subscription-management"
                className={
                  currentRoute.includes("subscription-management")
                    ? `${classes.activebtn}`
                    : ``
                }
                onClick={() =>
                  handleNavigate(
                    "subscription-management",
                    "/subscription-management"
                  )
                }
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button variant="text" className={classes.linkburron}>
                    <span className={classes.activeIcon1}>
                      <SubsManagementIcon />
                    </span>{" "}
                    {togglesidebar ? "Subscription Management" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("referrals") ? (
              <Link
                to="/referrals"
                className={
                  currentRoute.includes("referrals") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("referrals", "/referrals")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button
                    variant="text"
                    className={classes.linkburron}
                  >
                    <span className={classes.activeIcon1}>
                      <ReferralsIcon />
                    </span>
                    {togglesidebar ? "Referrals" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("invoicing") ? (
              <Link
                to="/invoicing"
                className={
                  currentRoute.includes("invoicing") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("invoicing", "/invoicing")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button variant="text" className={classes.linkburron}>
                    <span className={classes.activeIcon1}>
                      <InvoicingIcon />
                    </span>
                    {togglesidebar ? "Invoicing" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("userManagement") ? (
              <Link
                to="/user-management"
                className={
                  currentRoute.includes("user-management")
                    ? `${classes.activebtn}`
                    : ``
                }
                onClick={() =>
                  handleNavigate("user-management", "/user-management")
                }
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button variant="text" className={classes.linkburron}>
                    <span className={classes.activeIcon1}>
                      <UserManagementIcons />
                    </span>{" "}
                    {togglesidebar ? "User Management" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("auditTrail") ? (
              <Link
                to="/audit-trial"
                className={
                  currentRoute.includes("audit-trial") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("audit-trial", "/audit-trial")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button variant="text" className={classes.linkburron}>
                    <span className={classes.activeIcon1}>
                      <AuditTrialIcon />
                    </span>
                    {togglesidebar ? "Audit Trail" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("reports") ? (
              <Link
                to="/reports"
                className={
                  currentRoute.includes("reports") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("reports", "/reports")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button
                    variant="text"
                    className={classes.linkburron}
                  >
                    <span className={classes.activeIcon1}>
                      <ReportsIcon />
                    </span>
                    {togglesidebar ? "Reports" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("communication") ? (
              <Link
                to="/communication"
                className={
                  currentRoute.includes("communication") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("communication", "/communication")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button
                    variant="text"
                    className={classes.linkburron}
                  >
                    <span className={classes.activeIcon1}>
                      <CommunicationIcon />
                    </span>
                    {togglesidebar ? "Communication" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("supportTickets") ? (
              <Link
                to="/support-tickets"
                className={
                  currentRoute.includes("support-tickets") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("support-tickets", "/support-tickets")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button
                    variant="text"
                    className={classes.linkburron}
                  >
                    <span className={classes.activeIcon1}>
                      <TicketsIcon />
                    </span>
                    {togglesidebar ? "Support Tickets" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            {userInfo.permissions.includes("feedback") ? (
              <Link
                to="/feedback"
                className={
                  currentRoute.includes("feedback") ? `${classes.activebtn}` : ``
                }
                onClick={() => handleNavigate("feedback", "/feedback")}
              >
                <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                  <Button
                    variant="text"
                    className={classes.linkburron}
                  >
                    <span className={classes.activeIcon1}>
                      <FeedbackIcon />
                    </span>
                    {togglesidebar ? "Feedback" : null}
                  </Button>
                </li>
              </Link>
            ) : null}
            <Link
              to="/contact-us"
              className={
                currentRoute.includes("contact-us") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate("contact-us", "/contact-us")}
            >
              <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                <Button
                  variant="text"
                  className={classes.linkburron}
                >
                  <span className={classes.activeIcon1}>
                    <CallIcon />
                  </span>
                  {togglesidebar ? "Contact Us" : null}
                </Button>
              </li>
            </Link>
            <Link
              to="/blogs"
              className={
                currentRoute.includes("blogs") ? `${classes.activebtn}` : ``
              }
              onClick={() => handleNavigate("blogs", "/blogs")}
            >
              <li className={!togglesidebar ? `${classes.linkbtn}` : ``}>
                <Button
                  variant="text"
                  className={classes.linkburron}
                >
                  <span className={classes.activeIcon1} style={{marginLeft:"6px"}}>
                    <BlogIcon />
                  </span>
                  {togglesidebar ? "Blogs" : null}
                </Button>
              </li>
            </Link>
          </div>
        )}
      </div>
      {/* Sidebar */}
    </div>
  );
};

export default SideNavBar;
