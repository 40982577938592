import { authApi, getAuthorizationHeader } from "../../api/api";
import {
  SUPPORT_TICKET_CREATE_REQUEST,
  SUPPORT_TICKET_LIST_FAIL,
  SUPPORT_TICKET_LIST_SUCCESS,
  SUPPORT_TICKET_REQUEST,
} from "../constants/supportTicketConstants";

export const fetchSupportTickets =
  (page, limit, user_type) => async (dispatch) => {
    try {
      dispatch({
        type: SUPPORT_TICKET_REQUEST,
      });

      const { data } = await authApi.get(
        `/support-ticket/list?page=${page}&limit=${limit}&user_type=${user_type}&sort=first_name`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      console.log(data.support_tickets);

      dispatch({
        type: SUPPORT_TICKET_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUPPORT_TICKET_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const CreateSupportTicketAPI = (userEntry) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPORT_TICKET_CREATE_REQUEST,
    });

    const { data } = await authApi.post(`/support-ticket/create`, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    return {
      type: "success",
      message: data.message,
    };
  } catch (error) {
    return {type: "error", message: error.response.data.message };
    
  }
};

export const CloseSupportTicketAPI = (id, userEntry) => async (dispatch) => {
  dispatch({
    type: SUPPORT_TICKET_CREATE_REQUEST,
  });

  const { data } = await authApi.put(`/support-ticket/close/${id}`, userEntry, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  console.log(data);
};
