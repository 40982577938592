import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCustomerList,
  fetchCustomers,
} from "../../../Redux/actions/customerActions";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
} from "@mui/material";
import classes from "./customer.module.css";
import CustomerListItem from "./CustomerListItem";
import Pagination from "../../../Components//Pagination";
import { useNavigate } from "react-router-dom";
import FilterMenu from "../../../Components/customerPageFilter";
import InvoiceFilter from "../../../Components/InvoiceFilters";
import {
  CustomerDesignationFilter,
  CustomerIndustyFilter,
  subscriptionFilter,
} from "../../../Redux/actions/filterActions";
import {
  DatePickerCalendarIcon,
  OppositeSortArrawBtnIcons,
  RoundAddIcon,
  SearchIcon,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import DatePicker from "react-datepicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const Customers = ({ fromDate, toDate }) => {
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const [duration, setDuration] = useState("select");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const filterData = useSelector((state) => state.filter);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { industries, designation, invoice, sortBy, subscriptionTypes } =
    filterData || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [designations, setDesignations] = useState();
  const [industriesList, setIndustriesList] = useState();

  useEffect(() => {
    const filterArray = {
      userType: designation,
      industry: industries,
      active: invoice,
      subType: subscriptionTypes,
    };
    console.log(filterArray);
    dispatch(
      fetchCustomers(
        currentPage,
        rowCount,
        search,
        filterArray,
        sortBy,
        fromDate,
        toDate
      )
    );
  }, [
    dispatch,
    currentPage,
    rowCount,
    search,
    industries,
    designation,
    invoice,
    sortBy,
    subscriptionTypes,
    fromDate,
    toDate,
  ]);

  const customerList = useSelector((state) => state.customerData);
  const { loading, error, customers, totalCount } = customerList || {};

  useEffect(() => {
    const array = customers?.map((customer) => {
      return customer.designation;
    });
    const array1 = customers?.map((customer) => {
      return customer.company[0]?.industry;
    });
    const resultArray = array?.filter((value, index, self) => {
      return (
        value !== undefined && value !== "" && self.indexOf(value) === index
      );
    });
    const resultArray1 = array1?.filter((value, index, self) => {
      return (
        value !== undefined && value !== "" && self.indexOf(value) === index
      );
    });
    console.log(resultArray, resultArray1);
    setDesignations(resultArray);
    setIndustriesList(resultArray1);
  }, []);

  const handleSubmit = () => {
    dispatch(
      downloadCustomerList(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      )
    );
    handleClose();
  };

  return (
    <div>
      {window.location.pathname.includes("dashboard") ? null : (
        <div className={classes.HeaderInputContainer}>
          <div>
            <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.teamMemberSearch}
              id="firstNameinput"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
            />
          </div>{" "}
          <div>
            {/* <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              placeholder="Download Customer List"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DatePickerCalendarIcon />
                  </InputAdornment>
                ),
              }}
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className={classes.teamMemberReport}
              id="firstNameinput"
              value={search}
              onClick={handleClick}
              size="small"
            /> */}
            <Button
              sx={{
                color: "#737387",
                border: "#737387 1px solid",
              }}
              variant="outlined"
              className={classes.teamMemberReport}
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <span> Download Customer List</span>
              <DatePickerCalendarIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ minWidth: "450px" }}
            >
              <div style={{ padding: "20px" }}>
                <Typography
                  className={`mb-3 ${classes.modalHEadingCustomerFilter}`}
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Download Customer List
                </Typography>
                <ValidatorForm useref="form" onSubmit={handleSubmit}>
                  {/* <div className="mb-3">
                    <Typography className={`mb-2 ${classes.form_lable}`}>
                      Duration
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        width: 345,
                      }}
                      select
                      fullWidth
                      className={classes.inputs}
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      required={true}
                      size="small"
                    >
                      <MenuItem value="select">Select Duration</MenuItem>
                    </TextValidator>
                  </div> */}
                  <Typography className={`mb-3 ${classes.secondImportText}`}>
                    Date Range
                  </Typography>
                  <Typography className={`mb-2 ${classes.form_lable}`}>
                    from
                  </Typography>
                  <div className={`mb-3 ${classes.datePicker1}`}>
                    <DatePicker
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Select Date"
                      dateFormat="dd-MM-yyyy"
                    />
                    <span
                      className={classes.datePickerCalendarIcon}
                      style={{ top: "7px" }}
                    >
                      <DatePickerCalendarIcon />
                    </span>
                  </div>
                  <Typography className={`mb-2 ${classes.form_lable}`}>
                    to
                  </Typography>
                  <div className={`mb-3 ${classes.datePicker1}`}>
                    <DatePicker
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText="Select Date"
                       dateFormat="dd-MM-yyyy"
                    />
                    <span
                      className={classes.datePickerCalendarIcon}
                      style={{ top: "7px" }}
                    >
                      <DatePickerCalendarIcon />
                    </span>
                  </div>
                  <div className={`mt-4 ${classes.customerPagebtnSection}`}>
                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Confirm
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </Menu>
            <Button
              variant="contained"
              className={classes.MainHeaderCustomerButton}
              onClick={() => navigate("/communication/create-notification")}
            >
              <RoundAddIcon />
              <span> Create Notification </span>{" "}
            </Button>{" "}
          </div>{" "}
        </div>
      )}
      <div className={classes.tableContainer}>
        <Table
          style={{
            marginTop: "20px",
          }}
          className={classes.MainTable}
        >
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableHeadCell}>
                <div>
                  <span> Customer </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "company.company_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("company.company_name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Contact Person </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "user.name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("user.name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Location </span>
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortBy, "company.address_two")
                  }
                >
                  {sortBy?.includes("desc") &&
                  sortBy?.includes("company.address_two") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Industry </span>{" "}
                  {industriesList && industriesList.length > 0 && (
                    <FilterMenu
                      option={industriesList}
                      name={CustomerIndustyFilter}
                    />
                  )}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "company.industry")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("company.industry") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Subscriptions </span>
                  <FilterMenu
                    option={["active", "expired", "expiring_soon"]}
                    name={subscriptionFilter}
                  />
                  <span
                    onClick={() =>
                      handleSortClick(
                        dispatch,
                        sortBy,
                        "SubscriptionData.total"
                      )
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("SubscriptionData.total") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              {/* Base Example */}{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Designation </span>{" "}
                  {designations && designations.length > 0 && (
                    <FilterMenu
                      option={designations}
                      name={CustomerDesignationFilter}
                      clearAll={true}
                    />
                  )}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "designation")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("designation") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Mobile Number </span>{" "}
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Email ID </span>
                <span
                  onClick={() => handleSortClick(dispatch, sortBy, "email")}
                >
                  {sortBy?.includes("desc") && sortBy?.includes("email") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Joining Date </span>
                <span
                  onClick={() => handleSortClick(dispatch, sortBy, "createdAt")}
                >
                  {sortBy?.includes("desc") && sortBy?.includes("createdAt") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Status </span>
                  <InvoiceFilter />
                  {/* <span onClick={() => handleSortClick(dispatch, sortBy, "customer")}><SortArrawBtnIcons /></span>`` */}
                </div>
              </TableCell>{" "}
            </TableRow>{" "}
          </TableHead>{" "}
          <TableBody className={`list form-check-all ${classes.MainTable}`} style={{height: "100%"}}>
            {" "}
            {/* loop customer.data is > 0 */}{" "}
            {loading ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  Loading...
                </TableCell>{" "}
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  {" "}
                  {error}{" "}
                </TableCell>{" "}
              </TableRow>
            ) : customers && customers.length > 0 ? (
              customers
                .slice()
                .map((item, index) => (
                  <CustomerListItem key={index} customer={item} />
                ))
            ) : (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  No Data Found{" "}
                </TableCell>{" "}
              </TableRow>
            )}{" "}
          </TableBody>{" "}
        </Table>{" "}
      </div>
      <Pagination total={totalCount} />
    </div>
  );
};

export default Customers;
