import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
} from "@mui/material";
import classes from "./invoice.module.css";
import InvoiceListItems from "./InvoiceListItems";
import {
  DatePickerCalendarIcon,
  OppositeSortArrawBtnIcons,
  SearchIcon,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import FilterMenu from "../../../Components/customerPageFilter";
import { ContactPersonFilter } from "../../../Redux/actions/filterActions";
import Pagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadInvoiceList,
  getInvoices,
} from "../../../Redux/actions/customerActions";
import InvoiceFilter from "../../../Components/InvoiceFilters";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePicker from "react-datepicker";
import moment from "moment";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const Invoices = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { rowCount, currentPage } = PaginationData || {};
  const invoiceList = useSelector((state) => state.customerData);
  const [contactPersonList, setContactPersonList] = useState([]);
  const { loading, error, invoicesList, totalCount } = invoiceList || {};
  const filterData = useSelector((state) => state.filter);
  const { invoice, sortBy, contactPersons } = filterData || {};
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [duration, setDuration] = useState("select");

  useEffect(() => {
    const filterArray = {
      active: invoice,
      members: contactPersons,
    };
    dispatch(getInvoices(currentPage, rowCount, search, filterArray, sortBy));
  }, [
    dispatch,
    currentPage,
    rowCount,
    search,
    invoice,
    sortBy,
    contactPersons,
  ]);

  useEffect(() => {
    if (invoicesList?.length > 0) {
      const array = invoicesList.map((invoice) => {
        return `${invoice.customer_details?.first_name} ${invoice.customer_details?.last_name}`;
      });
      const resultArray = array.filter((value, index, self) => {
        return (
          value !== undefined && value !== "" && self.indexOf(value) === index
        );
      });
      console.log(resultArray);
      setContactPersonList(resultArray);
    }
  }, [invoicesList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    dispatch(
      downloadInvoiceList(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      )
    );
    handleClose();
  };

  return (
    <div>
      <div className={classes.HeaderInputContainer}>
        <div>
          <TextField
            sx={{
              "& legend": {
                display: "none",
              },
              "& fieldset": {
                top: 0,
              },
            }}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className={classes.teamMemberSearch}
            id="firstNameinput"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="small"
          />
        </div>
        <div>
          <Button
            sx={{
              color: "#737387",
              border: "#737387 1px solid",
            }}
            variant="outlined"
            className={classes.teamMemberReport}
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <span>Download Invoice</span>
            <DatePickerCalendarIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            className={classes.MainMenuDivCheck}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ minWidth: "450px" }}
          >
            <div style={{ padding: "20px" }}>
              <Typography
                className={`mb-3 ${classes.modalHEadingCustomerFilter}`}
                style={{
                  fontWeight: "600",
                }}
              >
                Download Invoice
              </Typography>
              <ValidatorForm useref="form" onSubmit={handleSubmit}>
                {/* <div className="mb-3">
                                    <Typography className={`mb-2 ${classes.form_lable}`}>
                                        Duration
                                    </Typography>
                                    <TextValidator
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                            width: 345,
                                        }}
                                        select
                                        fullWidth
                                        className={classes.inputs}
                                        value={duration}
                                        onChange={(e) => {
                                            setDuration(e.target.value);
                                        }}
                                        required={true}
                                        size="small"
                                    >
                                        <MenuItem value="select">Select Duration</MenuItem>
                                    </TextValidator>
                                </div> */}
                <Typography className={`mb-3 ${classes.secondImportText}`}>
                  Date Range
                </Typography>
                <Typography className={`mb-2 ${classes.form_lable}`}>
                  from
                </Typography>
                <div className={`mb-3 ${classes.datePicker1}`}>
                  <DatePicker
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Date"
                   dateFormat="dd-MM-yyyy"
                  />
                  <span
                    className={classes.datePickerCalendarIcon}
                    style={{ top: "7px" }}
                  >
                    <DatePickerCalendarIcon />
                  </span>
                </div>
                <Typography className={`mb-2 ${classes.form_lable}`}>
                  to
                </Typography>
                <div className={`mb-3 ${classes.datePicker1}`}>
                  <DatePicker
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="Select Date"
                    dateFormat="dd-MM-yyyy"
                  />
                  <span
                    className={classes.datePickerCalendarIcon}
                    style={{ top: "7px" }}
                  >
                    <DatePickerCalendarIcon />
                  </span>
                </div>
                <div className={`mt-4 ${classes.customerPagebtnSection}`}>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    style={{ border: "3px solid", color: "#1976d2" }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Confirm
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </Menu>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <Table
          style={{
            marginTop: "20px",
          }}
          className={classes.MainTable}
        >
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell />
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Customer </span>{" "}
                  {/* <FilterMenu
                                        option={Industries}
                                        name={CustomerIndustyFilter}
                                    />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(
                        dispatch,
                        sortBy,
                        "company_details.company_name"
                      )
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("company_details.company_name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Contact Person</span>{" "}
                  {contactPersonList && contactPersonList.length > 0 && (
                    <FilterMenu
                      option={contactPersonList}
                      name={ContactPersonFilter}
                    />
                  )}
                  <span
                    onClick={() =>
                      handleSortClick(
                        dispatch,
                        sortBy,
                        "customer_details.first_name"
                      )
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("customer_details.first_name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Referral Code </span>{" "}
                  {/* <FilterMenu
                                        option={Industries}
                                        name={CustomerIndustyFilter}
                                    />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(
                        dispatch,
                        sortBy,
                        "customer_details.my_referral_code"
                      )
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("customer_details.my_referral_code") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Mobile Number </span>{" "}
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Total Subscriptions </span>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Status </span>
                  <InvoiceFilter />
                </div>
              </TableCell>{" "}
            </TableRow>{" "}
          </TableHead>{" "}
        </Table>
        <Table>
          <TableBody className={`list form-check-all ${classes.MainTable}`}>
            {" "}
            {/* loop customer.data is > 0 */}{" "}
            {loading ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  Loading...
                </TableCell>{" "}
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  {" "}
                  {error}{" "}
                </TableCell>{" "}
              </TableRow>
            ) : invoicesList && invoicesList.length > 0 ? (
              invoicesList.map((item, index) => (
                <InvoiceListItems key={index} invoice={item} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  No Data Found{" "}
                </TableCell>{" "}
              </TableRow>
            )}{" "}
          </TableBody>{" "}
        </Table>{" "}
      </div>
      <Pagination total={totalCount} />
    </div>
  );
};

export default Invoices;
