import {
  editCustomer,
  fetchCustomer,
} from "../../../Redux/actions/customerActions";
import classes from "./customer.module.css";
import React, { useState } from "react";
import { Modal, Button, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import successimage from "../../../Assets/images/ILL_Sucess.png";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";

function EditCustomerModal(props) {
  const dispatch = useDispatch();
  const { values } = props;
  //console.log("Values",values);
  const { email, mobile_number, designation, first_name, last_name, _id } =
    values;
  const [success, setSuccess] = useState();
  const rootRef = React.useRef(null);
  const [firstName, setFirstName] = useState(first_name);
  const [modalToggle, setModalToggle] = useState(true);
  const [lastName, setLastName] = useState(last_name);
  const [editedDesignation, setEditedDesignation] = useState(designation);
  const [emailId, setEmailId] = useState(email);
  const [mobileNumber, setMobileNumber] = useState(mobile_number);

  function closeModal(data) {
    props.handleClose(false);
    setModalToggle(false);
    getCustomer();
  }

  const getCustomer = () => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  };

  const IsEmailCheck = (event) => {
    var x = event.charCode || event.keyCode;
    if ((x > 96 && x < 123) || x === 46 || x === 64 || (x > 47 && x < 58)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  const handleSubmit = () => {
    let editedCustomer = {
      first_name: firstName,
      last_name: lastName,
      designation: editedDesignation,
      email: emailId,
    };
    console.log(editedCustomer);
    dispatch(editCustomer(_id, editedCustomer));
    setSuccess(true);
  };

  /* handleSubmit Will be passed on later here*/
  return (
    <div>
      <Modal
        open={modalToggle}
        // onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          height: "max-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ width: "420px", height: "auto" }}
        >
          <Button
            variant="text"
            sx={{ minWidth: "unset" }}
            className={classes.closebtn}
            onClick={() => closeModal()}
          >
            <MdClose />
          </Button>
          {!success && (
            <div className={classes.AddMemberHeading}>
              <Typography>Edit Customer</Typography>
            </div>
          )}
          {!success ? (
            <ValidatorForm
              useref="form"
              onSubmit={handleSubmit}
              className={classes.formContainer}
            >
              <div className={classes.depFormContainer}>
                <div>
                  <div className="mt-3 mb-3">
                    <Typography className={classes.form_lable}>
                      First Name*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={firstName}
                      fullWidth
                      onChange={(e) => setFirstName(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["This Feild is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Last Name*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={lastName}
                      fullWidth
                      onChange={(e) => setLastName(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Department Name is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Designation*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={editedDesignation}
                      fullWidth
                      onChange={(e) => setEditedDesignation(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Department Name is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Mobile Number
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      fullWidth
                      disable
                      size="small"
                      validators={["required"]}
                      errorMessages={["Department Name is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Email
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={emailId}
                      onKeyPress={(e) => IsEmailCheck(e)}
                      onChange={(e) => setEmailId(e.target.value)}
                      fullWidth
                      disable
                      size="small"
                      validators={["required"]}
                      errorMessages={["Department Name is required"]}
                    />
                  </div>
                </div>
                <div className={"addMemberModalbtnSection"}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className={`mb-4`}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          ) : (
            <div className={classes.addMembersuccess}>
              <img
                src={successimage}
                alt="Success logo"
                width={200}
                height={200}
              />
              <Typography className={classes.successMainText}>
                Customer Edited Successfully
              </Typography>
              <Typography className={classes.successSubText}>
                Your Changes to the Customer Has been Updated
              </Typography>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default EditCustomerModal;
