import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { paginationEdit } from "../Redux/actions/customerActions";
import { Button } from "react-bootstrap";
import classes from "./index.module.css";
import {
  DoubleNextRight,
  DoublePrevLeft,
  SingleNextRight,
  SinglePrevLeft,
} from "./Icons/Icons";

function Pagination(Props) {
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};

  const dispatch = useDispatch();
  const [page, setPage] = useState(currentPage ? currentPage : 1);
  const [rowCountEdit, setRowCountEdit] = useState(rowCount ? rowCount : 10);

  let maxPage = Math.ceil(Props.total / rowCountEdit);

  let currentEntryStart = page * rowCountEdit - rowCountEdit + 1;
  let currentEntryEnd = page * rowCountEdit;

  if (currentEntryEnd > Props.total) {
    currentEntryEnd = Props.total;
  }

  const onNext = () => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const handleLastPage = () => {
    setPage(maxPage);
  };

  const onBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Update pagination when rowCount or page changes
  useEffect(() => {
    dispatch(paginationEdit(rowCountEdit, page));
  }, [rowCountEdit, page, dispatch]);

  // Reset to default when component mounts or when total changes
  useEffect(() => {
    setRowCountEdit(10); // Default row count
    setPage(1); // Reset to first page
  }, [Props.total]); // Reset if total entries change

  return (
    <div className="d-flex justify-content-end mt-2">
      <div className="pagination-wrap hstack gap-2">
        {Props.from !== "supportTicket" ? (
          <Typography className="mb-0 text-muted text">
            Showing <b>{currentEntryStart}</b> to <b>{currentEntryEnd}</b> of{" "}
            <b>{Props.total}</b> entries | Page <b>{page}</b> | Showing{" "}
            <b>{rowCountEdit}</b> rows
          </Typography>
        ) : (
          ""
        )}
        <Button
          onClick={() => setPage(1)}
          className={`page-item pagination-prev ${classes.paginationbtn}`}
        >
          <span>
            <DoublePrevLeft />
          </span>
        </Button>
        <Button
          onClick={() => onBack()}
          className={`page-item pagination-prev ${classes.paginationbtn}`}
        >
          <span>
            <SinglePrevLeft />
          </span>
        </Button>

        <Typography className="page" href="#">
          {page}
        </Typography>
        <Button
          onClick={() => onNext()}
          className={`page-item pagination-next ${classes.paginationbtn}`}
        >
          <span>
            <SingleNextRight />
          </span>
        </Button>
        <Button
          onClick={() => handleLastPage()}
          className={`page-item pagination-next ${classes.paginationbtn}`}
        >
          <span>
            <DoubleNextRight />
          </span>
        </Button>

        <span className="page-item">
          <select
            className="form-select bg-white"
            aria-label=" select example"
            value={rowCountEdit} // Use value instead of defaultValue
            onChange={(e) => {
              setRowCountEdit(Number(e.target.value)); // Ensure it's a number
              setPage(1); // Reset to page 1 when row count changes
            }}
            onClick={(e) => e.preventDefault()}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </span>
      </div>
    </div>
  );
}

export default Pagination;
