import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import GuestLayout from "./Components/Layouts/guestLayout";
import Login from "./Pages/Authentication/Login";
import DefaultLayout from "./Components/Layouts/DefaultLayout";
import Homepage from "./Pages/Home/Dashboard/Homepage";
import MyProfile from "./Pages/Home/Profile/my_profile";
import ChangePassword from "./Pages/Home/Profile/ChangePassword";
import Invoices from "./Pages/Home/Invoice/Invoices";
import Subscription from "./Pages/Home/SubscriptionManagement/Subscription";
import EditSubscription from "./Pages/Home/SubscriptionManagement/EditSubscription";
import AddSubscription from "./Pages/Home/SubscriptionManagement/AddSubscription";
import CustomerIndex from "./Pages/Home/Customer/CustomerIndex";
import CustomerProfileIndex from "./Pages/Home/Customer/CustomerProfileIndex";
import RefferalIndex from "./Pages/Home/Referrals/Index";
import AddPromoCode from "./Pages/Home/Referrals/AddPromoCode";
import SpecialLayout from "./Components/Layouts/SpecialLayout";
import InvoiceViewPage from "./Pages/Home/Invoice/InvoiceViewPage";
import UserManagement from "./Pages/Home/UserManagement/userManagement";
import AddUserPage from "./Pages/Home/UserManagement/AddUserPage";
import AuditTrial from "./Pages/Home/AuditTrial/auditTrial";
import Reports from "./Pages/Home/Reports/reports";
import Communication from "./Pages/Home/Communication/communication";
import CreateNotificationPage from "./Pages/Home/Communication/createNotification";
import SuportTicketIndex from "./Pages/Home/SupportTicket/suportTicketIndex";
import CreateSupportTicket from "./Pages/Home/SupportTicket/createSupportTicket";
import FeedBackIndex from "./Pages/Home/Feedback/feedbackIndex";
import InviteNewCustomer from "./Pages/Home/Customer/InviteNewCustomer";
import CommunicationViewPage from "./Pages/Home/Communication/communicationViewPage";
import TeamLeaderContactUs from "./Pages/Home/Contact Us/teamLeadercontactUs";
import Blogs from "./Pages/Home/Blogs/Blogs";
import CreateBlogsPage from "./Pages/Home/Blogs/CreateBlogsPage";
import NotificationsPage from "./Components/notificationsPage";

const Router = createBrowserRouter([
  
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: <SpecialLayout />,
    children: [
      {
        path: "/invoices/:name",
        element: <InvoiceViewPage />
      },
    ]
  },
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Homepage />,
      },
      {
        path: "/my-profile",
        element: <MyProfile />,
      },
      {
        path: "/customers",
        element: <CustomerIndex />
      },
      {
        path: "/customers/invite",
        element: <InviteNewCustomer />
      },
      {
        path: "/reset-password",
        element: <ChangePassword />
      },
      {
        path: "/customer/profile/:id",
        element: <CustomerProfileIndex />
      },
      {
        path: "/referrals",
        element: <RefferalIndex />
      },
      {
        path: "/referrals/add-promocode",
        element: <AddPromoCode />
      },
      {
        path: "/referrals/edit-promocode/:id",
        element: <AddPromoCode />
      },
      {
        path: "/invoicing",
        element: <Invoices />
      },
      {
        path: "/subscription-management",
        element: <Subscription />
      },
      {
        path: "/subscription-management/add",
        element: <AddSubscription />
      },
      {
        path: "/subscription-management/edit",
        element: <EditSubscription />
      },
      {
        path: "/user-management",
        element: <UserManagement />
      },
      {
        path: "/user-management/add-user",
        element: <AddUserPage />
      },
      {
        path: "/audit-trial",
        element: <AuditTrial />
      },
      {
        path: "/reports",
        element: <Reports />
      },
      {
        path: "/communication",
        element: <Communication />
      },
      {
        path: "/communication/create-notification",
        element: <CreateNotificationPage />
      },
      {
        path: "/communication/edit-notification/:id",
        element: <CreateNotificationPage />
      },
      {
        path: "/communication/:id",
        element: <CommunicationViewPage />
      },
      {
        path: "/support-tickets",
        element: <SuportTicketIndex />
      },
      {
        path: "/support-tickets/create-ticket",
        element: <CreateSupportTicket />
      },
      {
        path: "/feedback",
        element: <FeedBackIndex />
      },
      {
        path: "/contact-us",
        element: <TeamLeaderContactUs />
      },
      {
        path: "/blogs",
        element: <Blogs />
      },
      {
        path: "/notifications",
        element: <NotificationsPage />
      },
      {
        path: "/blogs/create-blogs",
        element: <CreateBlogsPage />
      },
      {
        path: "/blogs/edit-blogs/:id",
        element: <CreateBlogsPage />
      },
      {
        basename: '/admin',
    }
    ],
  },
]);
export default Router;
