import React, { useState } from "react";
import classes from "./reports.module.css";
import {
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DatePickerCalendarIcon,
  OppositeSortArrawBtnIcons,
  SearchIcon,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import ReportListItem from "./reportListItem";
import Pagination from "../../../Components/Pagination";
import {
  DownloadActiveCustomersReport,
  fetchActiveCustomersReport,
} from "../../../Redux/actions/ReportActions";
import moment from "moment";
import { handleSortClick } from "../../../Utilities/commonfunctions";
import { useEffect } from "react";

const Reports = () => {
  const dispatch = useDispatch();
  const [reportType, setReportType] = useState("0");
  const [reportVisible, setReportvisible] = useState(false);
  const [downloadType, setDownloadType] = useState("downloadas");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const reportListData = useSelector((state) => state.reportData);
  const { loading, error, reportList, totalCount } = reportListData || {};
  const { sortBy } = useSelector((state) => state.filter);

  const handleChange = (event) => {
    setReportType(event.target.value);
  };

  const handleSubmit = () => {
    console.log(reportType);
    setReportvisible(true);
    if (reportType === "activeCustomers") {
      dispatch(
        fetchActiveCustomersReport(
          currentPage,
          rowCount,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          sortBy
        )
      );
    }
  };

  const handleTypeChange = (value) => {
    setDownloadType(value);
    console.log(value);
    if (value === "pdf") {
      dispatch(
        DownloadActiveCustomersReport(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          value
        )
      );
    } else if (value === "excel") {
      dispatch(
        DownloadActiveCustomersReport(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          value
        )
      );
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [currentPage, rowCount, sortBy]);

  return (
    <div>
      <div>
        <div className={classes.ReportTextFieldsContainer}>
          <div>
            <Typography
              className={`${classes.teamPageHeading2} ${classes.textLable}`}
            >
              Report Type
            </Typography>
            <Select
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                width: "100%",
                background: "white",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reportType}
              size="small"
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={"0"} style={{ display: "none" }}>
                Select Report Type
              </MenuItem>
              <MenuItem value={"activeCustomers"}>Active Customers</MenuItem>
            </Select>
          </div>
          <div>
            <Typography
              className={`${classes.teamPageHeading2} ${classes.textLable}`}
            >
              Start Date
            </Typography>
            <div className={classes.datePicker1}>
              <DatePicker
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                size="small"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                maxDate={new Date()}
                 dateFormat="dd-MM-yyyy"
              />
              <span
                className={classes.datePickerCalendarIcon}
                style={{ top: "7px" }}
              >
                <DatePickerCalendarIcon />
              </span>
            </div>
          </div>
          <div>
            <Typography
              className={`${classes.teamPageHeading2} ${classes.textLable}`}
            >
              End Date
            </Typography>
            <div className={classes.datePicker1}>
              <DatePicker
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                size="small"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                maxDate={new Date()}
                placeholderText="End Date"
                 dateFormat="dd-MM-yyyy"
              />
              <span
                className={classes.datePickerCalendarIcon}
                style={{ top: "7px" }}
              >
                <DatePickerCalendarIcon />
              </span>
            </div>
          </div>
          <div>
            <Typography
              className={`${classes.teamPageHeading2} ${classes.textLable}`}
            ></Typography>
            <Button
              variant="contained"
              className={classes.teamMemberBtn}
              onClick={() => handleSubmit()}
            >
              <span
                className={classes.RepotsearchIcon}
                style={{ width: "16px", marginRight: "7px" }}
              >
                <SearchIcon />{" "}
              </span>{" "}
              Search Report
            </Button>
          </div>
        </div>
        <div className={classes.DownloadTypeBtn}>
          <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
            {/* <InputLabel style={{}}>Download As</InputLabel> */}
            <Select
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={downloadType}
              label="Download As"
              size="small"
              onChange={(e) => handleTypeChange(e.target.value)}
            >
              <MenuItem value={"downloadas"}>Download As</MenuItem>
              <MenuItem value={"pdf"}>PDF</MenuItem>
              <MenuItem value={"excel"}>Excel</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {reportType !== "" && reportVisible && (
        <div>
          <div className={classes.TableContainer}>
            <Table className={classes.MainTable}>
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Customer </span>{" "}
                      {/* <FilterMenu
                        option={Industries}
                        name={CustomerIndustyFilter}
                      />{" "} */}
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "companyInfo.company_name"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("companyInfo.company_name") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.tableHeadCell}
                    style={{ minWidth: 220 }}
                  >
                    <div className={classes.tableHEadercelldiv}>
                      <span> Contact Person </span>{" "}
                      {/* <FilterMenu
                        option={Industries}
                        name={CustomerIndustyFilter}
                      />{" "} */}
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "userInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("userInfo.name") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span> Industry </span>
                    <span
                      onClick={() =>
                        handleSortClick(
                          dispatch,
                          sortBy,
                          "companyInfo.industry"
                        )
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("companyInfo.industry") ? (
                        <OppositeSortArrawBtnIcons />
                      ) : (
                        <SortArrawBtnIcons />
                      )}
                    </span>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span>Designation</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "designation")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("designation") ? (
                        <OppositeSortArrawBtnIcons />
                      ) : (
                        <SortArrawBtnIcons />
                      )}
                    </span>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span>Mobile Number</span>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span>Email ID</span>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Joining Date </span>
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "userInfo.createdAt"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("userInfo.createdAt") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <td colSpan="9" className="text-center">
                      Loading...
                    </td>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <td colSpan="9" className="text-center">
                      {error}
                    </td>
                  </TableRow>
                ) : reportList && reportList.length > 0 ? (
                  reportList.map((item, index) => (
                    <ReportListItem key={index} item={item} />
                  ))
                ) : (
                  <TableRow>
                    <td colSpan="9" className="text-center">
                      No Data Found
                    </td>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination total={totalCount} />
        </div>
      )}
    </div>
  );
};

export default Reports;
