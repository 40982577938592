import React, { useEffect } from 'react'
import classes from "./dashboard.module.css"
import { Paper, Typography, Avatar, Divider, Button, TextField } from '@mui/material';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { VictoryPie } from "victory";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { VictoryLabel } from "victory-core";
import Customers from '../Customer/Customers';
import { getCustomerPieChartDetails, getSubscriptionPieChartDetails, getSupportTicketPieChartDetails } from '../../../Redux/actions/piechartActions';
import { range } from '../../../Utilities/commonContants';
import { useState } from 'react';
import moment from 'moment';
import { DatePickerCalendarIcon } from '../../../Components/Icons/Icons';
import { paginationEdit } from '../../../Redux/actions/customerActions';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const togglesidebar = useSelector((state) => state.userLogin.togglesidebar);
  const { ativeCustomer, subscriptionpiechart, supportTicket } = useSelector((state) => state.piechartData);
  console.log("subscriptionpiechart",subscriptionpiechart);
  
  const act_subs = subscriptionpiechart?.total > 0
  ? Math.floor((subscriptionpiechart?.active + subscriptionpiechart?.expiry_soon) / subscriptionpiechart?.total * 100)
  : 0;

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      const updateFromData=moment(fromDate).format("DD-MM-YYYY");
      const updateToDate=moment(toDate).format("DD-MM-YYYY");
      setSelectedDate(`${updateFromData} to ${updateToDate}`);
    }
  }, [fromDate, toDate]);

  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    handleDate(picker)
  };
const handleDate=(picker)=>{
  setFromDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
  setToDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
}
  // useEffect(() => {
  //   console.log(ativeCustomer);
  //   console.log(subscriptionpiechart);
  //   console.log(supportTicket);
  // }, [ativeCustomer, subscriptionpiechart, supportTicket]);

  useEffect(() => {
    dispatch(getCustomerPieChartDetails(fromDate, toDate));
    dispatch(getSubscriptionPieChartDetails(fromDate, toDate));
    dispatch(getSupportTicketPieChartDetails(fromDate, toDate));
  }, [dispatch, fromDate, toDate]);

  const data = [
    { x: "Expired", y: ativeCustomer?.in_active },
    { x: "Expiring", y: ativeCustomer?.active },
  ];
  const data2 = subscriptionpiechart?.total > 0
  ? [
      { x: "Expired", y: 100 - act_subs },
      { x: "Expiring", y: act_subs },
    ]
  : [
      { x: "Expired", y: 0 },
      { x: "Expiring", y: 0 },
    ];
  const data3 = [
    { x: "Expired", y: subscriptionpiechart?.active },
    { x: "Expiring", y: subscriptionpiechart?.expiry_soon },
    { x: "Expiring", y: subscriptionpiechart?.expired },
  ]
  const data4 = [
    { x: "Expired", y: 0 },
    { x: "Expiring", y: 0 },
  ];

  const data5 = [
    { x: "Expired", y: supportTicket?.pending },
    { x: "Expiring", y: supportTicket?.closed },
  ]
  useEffect(() => {
    dispatch(paginationEdit(10, 1));
  }, [fromDate]);
  console.log(data2);
  
  return (
    <div>
      <div className={classes.datePicker1}>
        <DateRangePicker
          initialSettings={{ ranges: range }}
          alwaysShowCalendars={true}
          onApply={handleEvent}
        >
          <div>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              size="small"
              placeholder="Today"
              value={selectedDate}
            />
            <span
              className={classes.datePickerCalendarIcon}
              style={{ right: "10px" }}
            >
              <DatePickerCalendarIcon />
            </span>
          </div>
        </DateRangePicker>
      </div>
      <div className={classes.DashboardFirstContainer}>
        <Paper className={classes.Pichart1Container}>
          <Typography className={classes.piechartHEadingMain}>Customers</Typography>
          <Typography className={`mt-2 ${classes.pieChartSubHEading}`}>Daily Active users: <span style={{ color: "#0A1F41", fontWeight: 600 }}>{ativeCustomer?.active}</span></Typography>
          {ativeCustomer && (
            <div className={classes.displayFlex} style={{ columnGap: "40px", position: "relative" }} >
              <svg viewBox="0 0 450 450" width="170px" >
                <VictoryPie
                  standalone={false}
                  width={450}
                  height={450}
                  data={data}
                  innerRadius={125}
                  colorScale={["#C52A38", "#227DC3"]}
                  labels={() => null} // Hide labels
                />
              </svg>
              <svg viewBox="0 0 450 450" width={togglesidebar ? "100px" : "110px"} className={classes.firstPieChartSubChart}>
                <VictoryPie
                  standalone={false}
                  width={450}
                  height={450}
                  data={data}
                  innerRadius={160}
                  colorScale={["#C52A38", "transparent"]}
                  labels={() => null} // Hide labels
                />
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={225}
                  y={255}
                  text={[ativeCustomer?.total, "Total"]}
                  style={[{ fontSize: 85, fontWeight: 600 }, {
                    fontSize: "45px",
                    fontWeight: 400,
                    fill: "#6B778C",
                  }]}
                />
              </svg>
              <div>
                <Typography className={classes.displayFlex}>
                  <Avatar
                    sx={{
                      bgcolor: `#227DC3`,
                      width: "12px",
                      height: "12px",
                    }}
                  >
                    {" "}
                  </Avatar>
                  <span className={classes.pieChartSubHEading}>Active</span>
                </Typography>
                <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{ativeCustomer?.active}</Typography>
                <Typography className={`mt-3 ${classes.displayFlex}`}>
                  <Avatar
                    sx={{
                      bgcolor: `#C52A38`,
                      width: "12px",
                      height: "12px",
                    }}
                  >
                    {" "}
                  </Avatar>
                  <span className={classes.pieChartSubHEading}>Inactive</span>
                </Typography>
                <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{ativeCustomer?.in_active}</Typography>
              </div>
            </div>
          )}
        </Paper>
        <Paper className={classes.Pichart2Container}>
          <Typography className={classes.piechartHEadingMain}>Subscription</Typography>
          <Typography className={`mt-2 ${classes.pieChartSubHEading}`}>{" "}</Typography>
          {subscriptionpiechart && (
            <div className={classes.TwoPieChartContainer}>
              <div className={classes.displayFlex} style={{ columnGap: "40px", width: "49%" }}>
                <svg viewBox="0 0 450 450" width="170px" >
                  <VictoryPie
                    standalone={false}
                    width={450}
                    height={450}
                    data={data2}
                    innerRadius={125}
                    colorScale={["#C52A38", "#240051"]}
                    labels={() => null} // Hide labels
                  />
                  <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    x={225}
                    y={250}
                    text={[`${data2[1].y+data2[0].y} %`, "Total"]}
                    style={[{ fontSize: 65, fontWeight: 600 }, {
                      fontSize: "30px",
                      fontWeight: 400,
                      fill: "#6B778C"
                    }]}
                  />
                </svg>
                <div>
                  <Typography className={classes.displayFlex}>
                    <Avatar
                      sx={{
                        bgcolor: `#240051`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Active</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{data2[1].y}%</Typography>
                  <Typography className={`mt-3 ${classes.displayFlex}`}>
                    <Avatar
                      sx={{
                        bgcolor: `#C52A38`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Inactive</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{data2[0].y}%</Typography>
                </div>
              </div>
              <Divider orientation="vertical" flexItem style={{
                height: "155px",
                borderColor: "#DADEF2",
                borderWidth: "1px"
              }} />
              <div className={classes.displayFlex} style={{ columnGap: "40px", width: "50%" }}>
                <svg viewBox="0 0 450 450" width="170px" >
                  <VictoryPie
                    standalone={false}
                    width={450}
                    height={450}
                    data={data3}
                    innerRadius={125}
                    colorScale={["#240051", "#227DC3", "#C52A38"]}
                    labels={() => null} // Hide labels
                  />
                  <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    x={225}
                    y={250}
                    text={[subscriptionpiechart.total, "Total"]}
                    style={[{ fontSize: 65, fontWeight: 600 }, {
                      fontSize: "30px",
                      fontWeight: 400,
                      fill: "#6B778C"
                    }]}
                  />
                </svg>
                <div>
                  <Typography className={classes.displayFlex}>
                    <Avatar
                      sx={{
                        bgcolor: `#C52A38`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Expired</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{subscriptionpiechart?.expired}</Typography>
                  <Typography className={`mt-2 ${classes.displayFlex}`}>
                    <Avatar
                      sx={{
                        bgcolor: `#227DC3`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Expiring Soon</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{subscriptionpiechart?.expiry_soon}</Typography>
                  <Typography className={`mt-2 ${classes.displayFlex}`}>
                    <Avatar
                      sx={{
                        bgcolor: `#240051`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Resubscribed</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{subscriptionpiechart?.active}</Typography>
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
      <div className={classes.DashboardSecondContainer}>
        <Paper className={classes.Pichart3Container}>
          <Typography className={classes.piechartHEadingMain}>Mobile App</Typography>
          <div className={classes.displayFlex} style={{ columnGap: "40px" }}>
            <svg viewBox="0 0 450 450" width="170px" >
              <VictoryPie
                standalone={false}
                width={450}
                height={450}
                data={data4.some(item => item.y !== 0) ? data4 : [{ x: "Closed", y: 1 }]}
                innerRadius={125}
                colorScale={data4.some(item => item.y !== 0) ? ["#240051", "#227DC3"] : ["#f7fbff"]}
                // data={data4}
                // innerRadius={125}
                // colorScale={["#240051", "#227DC3"]}
                labels={() => null} // Hide labels
              />
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                x={225}
                y={250}
                text={[0, "Total"]}
                style={[{ fontSize: 65, fontWeight: 600 }, {
                  fontSize: "30px",
                  fontWeight: 400,
                  fill: "#6B778C"
                }]}
              />
            </svg>
            <div className={classes.displayFlex} style={{ columnGap: "40px" }}>
              <div>
                <Typography className={classes.displayFlex}>
                  <Avatar
                    sx={{
                      bgcolor: `#227DC3`,
                      width: "12px",
                      height: "12px",
                    }}
                  >
                    {" "}
                  </Avatar>
                  <span className={classes.pieChartSubHEading}>Downloads</span>
                </Typography>
                <Typography className={`mt-2 ${classes.piechartValuyeText}`}>0</Typography>
              </div>
              <div>
                <Typography className={`${classes.displayFlex}`}>
                  <Avatar
                    sx={{
                      bgcolor: `#240051`,
                      width: "12px",
                      height: "12px",
                    }}
                  >
                    {" "}
                  </Avatar>
                  <span className={classes.pieChartSubHEading}>Subscriptions</span>
                </Typography>
                <Typography className={`mt-2 ${classes.piechartValuyeText}`}>0</Typography>
              </div>
            </div>
          </div>
        </Paper>
        <Paper className={classes.Pichart3Container}>
          <Typography className={classes.piechartHEadingMain}>Support Ticket</Typography>
          {/* <Typography className={`mt-2 ${classes.pieChartSubHEading}`}>Daily Active users: <span style={{ color: "#0A1F41", fontWeight: 600 }}>80</span></Typography> */}
          {supportTicket && (
            <div className={classes.displayFlex} style={{ columnGap: "40px", position: "relative" }}>
              <svg viewBox="0 0 450 450" width="170px" >
                <VictoryPie
                  standalone={false}
                  width={450}
                  height={450}
                  data={data5.some(item => item.y !== 0) ? data5 : [{ x: "Closed", y: 1 }]}
                  innerRadius={150}
                  colorScale={data5.some(item => item.y !== 0) ? ["#007fc321", "#227DC3"] : ["#f7fbff"]} // Change color scale if any value is not zero
                  // colorScale={["#007fc321", "#227DC3"]}
                  labels={() => null} // Hide labels
                />
              </svg>
              <svg viewBox="0 0 450 450" width="120px" className={classes.secondPie}>
                <VictoryPie
                  standalone={false}
                  width={450}
                  height={450}
                  data={data5}
                  innerRadius={150}
                  colorScale={["#C52A38", "#007fc321"]}
                  labels={() => null} // Hide labels
                />
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={225}
                  y={250}
                  text={[supportTicket?.total, "Total"]}
                  style={[{ fontSize: 65, fontWeight: 600 }, {
                    fontSize: "30px",
                    fontWeight: 400,
                    fill: "#6B778C"
                  }]}
                />
              </svg>
              <div className={classes.displayFlex} style={{ columnGap: "40px" }}>
                <div>
                  <Typography className={classes.displayFlex}>
                    <Avatar
                      sx={{
                        bgcolor: `#227DC3`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Completed</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{supportTicket?.closed}</Typography>
                </div>
                <div>
                  <Typography className={`${classes.displayFlex}`}>
                    <Avatar
                      sx={{
                        bgcolor: `#C52A38`,
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      {" "}
                    </Avatar>
                    <span className={classes.pieChartSubHEading}>Pending</span>
                  </Typography>
                  <Typography className={`mt-2 ${classes.piechartValuyeText}`}>{supportTicket?.pending}</Typography>
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
      <div className='mt-3'>
        <Box style={{ position: "relative" }}>
          <div >
            <Button
              variant='text'
              className={classes.ViewAllBtn}
              onClick={() => navigate("/customers")}
            >
              View all
            </Button>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.HeadTabButton}
              aria-label="basic tabs example"
            >
              <Tab
                label={"Subscription Actions"}
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={"Latest Customers"}
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className={classes.TabPanel}>
            <Customers fromDate={fromDate} toDate={toDate} />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.TabPanel}>
            <Customers fromDate={fromDate} toDate={toDate} />
          </TabPanel>
        </Box>
      </div>
    </div>
  )
}

export default Homepage